// extracted by mini-css-extract-plugin
export var bg = "stamp-duty-calc-module--bg--UeMql";
export var buyerType = "stamp-duty-calc-module--buyerType--CFrrw";
export var buyerTypeCon = "stamp-duty-calc-module--buyerTypeCon--7UFnK";
export var buyerTypeInput = "stamp-duty-calc-module--buyerTypeInput--CJVTO";
export var calcButton = "stamp-duty-calc-module--calcButton--grblF";
export var calcCon = "stamp-duty-calc-module--calcCon--WHg6i";
export var checkmark = "stamp-duty-calc-module--checkmark--Gblpf";
export var col0 = "stamp-duty-calc-module--col0--oWohi";
export var col1 = "stamp-duty-calc-module--col1--L4cEN";
export var col2 = "stamp-duty-calc-module--col2--fzUoQ";
export var col3 = "stamp-duty-calc-module--col3--0qNhj";
export var con = "stamp-duty-calc-module--con--gRsYd";
export var currency = "stamp-duty-calc-module--currency--Bz6Nf";
export var disclaimer = "stamp-duty-calc-module--disclaimer--y2Lg5";
export var errorMessage = "stamp-duty-calc-module--errorMessage--GvE-Y";
export var finalFigure = "stamp-duty-calc-module--finalFigure--gVGsL";
export var header = "stamp-duty-calc-module--header--tP0zi";
export var hide = "stamp-duty-calc-module--hide--fqwF1";
export var housePrice = "stamp-duty-calc-module--housePrice--HfweR";
export var housePriceCon = "stamp-duty-calc-module--housePriceCon--Q3Qo6";
export var housePriceInput = "stamp-duty-calc-module--housePriceInput--DsSQT";
export var resultsCon = "stamp-duty-calc-module--resultsCon--X8D2P";
export var resultsMain = "stamp-duty-calc-module--resultsMain--5uW1i";
export var resultsTable = "stamp-duty-calc-module--resultsTable--PWuw+";
export var taxBandLong = "stamp-duty-calc-module--taxBandLong--o6etW";
export var taxBandShort = "stamp-duty-calc-module--taxBandShort--yn8Vs";